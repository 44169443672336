<template>
  <b-modal id="modal-1" size="xl" hide-footer title="Billing History">
    <BillingHistoryTable :company-id="companyId" :country-id="countryId"
                         :token="token" :currency-symbol="currencySymbol"
                         :customer-identifier="customerIdentifier"/>
  </b-modal>
</template>

<script>
import BillingHistoryTable from "@/components/MyAccount/BillingHistoryTable.vue";

export default {
  components: {
    BillingHistoryTable
  },
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    currencySymbol: {
      type: String
    }
  },
}
</script>
