<template>
  <div>
    <vue-confirm-dialog></vue-confirm-dialog>
    <notifications group="my_account"/>
    <b-overlay :show="showOverlay" rounded="sm">
      <div :hidden="this.hideMyAccount" class="card" style="border-radius: 0;"
           :style="{'background-color': getBackgroundColor(), 'color': getTextColor()}">
        <div class="row">
          <hr class="row_border" :style="{'border-color': getLineBorderColor()}"/>
          <div class="col-xl-12">
            <p class="cookieHeading">Plan Details </p>
          </div>

          <div class="col-xl-6">
            <p class="cookieDescription">{{ plan.plan_name }} </p>
          </div>

          <div class="col-xl-6 text-right">
            <a style="display: none" href="#">Cancel Plan</a>
          </div>

          <hr class="row_border" :style="{'border-color': getLineBorderColor()}"/>

          <div class="col-xl-12">
            <p class="cookieHeading">Membership & Billing</p>
          </div>

          <div class="col-xl-6">
            <p class="cookieDescription" v-if="plan.card_last_4">
              ****{{ plan.card_last_4 }} {{ plan.card_network && (plan.card_network).toUpperCase() }}
            </p>
          </div>

          <div class="col-xl-6 text-right">
            <a href="#" @click="initPayment()"
               style="text-decoration: underline" :style="{'color': getTextColor()}">
              {{ plan.card_last_4 ? 'Change' : 'Add' }} Payment Method
            </a>
          </div>

          <div class="col-xl-6">
            <p class="cookieDescription" v-if="plan.subscription_end_date" v-html="getSubscriptionPeriodText()">

            </p>
          </div>

          <div class="col-xl-6 text-right" v-if="plan.subscription_end_date">
            <a @click="openModel()" href="#" style="text-decoration: underline" :style="{'color': getTextColor()}">
              View Billing History
            </a>
            <br/>
            <a v-if="plan.subscription_status === 'ACTIVE' || plan.subscription_status === 'TRIAL'"
               @click="cancelSubscriptionButtonClick()" href="#"
               style="text-decoration: underline"
               :style="{'color': getTextColor()}">
              Cancel Subscription
            </a>
          </div>

          <hr class="row_border" :style="{'border-color': getLineBorderColor()}"/>

          <div v-if="prepaidUsage.length > 0" class="w-100">
            <div class="col-xl-12">
              <p class="cookieHeading">Manage Usage</p>
            </div>
            <div class="col-xl-6">
              <MinusPlus v-for="(item, index) in prepaidUsage" :max="item.max" :min="item.min"
                         :value="item.qty" :label="item.label" :key="index" :index="index"
                         @onChange="onPrepaidUsageUpdate" :disable-minus="true"/>
              <p>Amount : {{ currencySymbol }} {{ newPrepaidPrice }}</p>
              <a href="#" @click="updatePrepaidMeter"
                 style="text-decoration: underline"
                 :style="{'color': getTextColor()}">
                Update Usage
              </a>
            </div>
            <hr class="row_border" :style="{'border-color': getLineBorderColor()}"/>
          </div>

        </div>
      </div>

      <div :hidden="!this.hideMyAccount" class="card" style="border-radius: 0"
           :style="{'background-color': getBackgroundColor(), 'color': getTextColor()}">
        <div class="row">
          <div class="col-xl-12 text-center">
            <h5>You are not subscribed to any plan </h5>
          </div>
        </div>
      </div>

    </b-overlay>
    <BillingHistory v-if="billingHistoryRender" :company-id="companyId" :country-id="countryId"
                    :token="token" :api-key="apiKey" :currency-symbol="currencySymbol"
                    :customer-identifier="customerIdentifier"/>
    <Model ref="model" v-if="billingHistoryRender" :company-id="companyId" :country-id="countryId"
           :token="token" :api-key="apiKey" :currency-symbol="currencySymbol"
           :customer-identifier="customerIdentifier"/>
  </div>
</template>

<script>
import BillingHistory from './BillingHistory.vue'
import axios from "axios";
import moment from 'moment'
import Model from '../Model'
import MinusPlus from "@/components/MinusPlus/index.vue";
import util from "@/util/usageUtil";

export default {
  data() {
    return {
      showOverlay: false,
      billingHistoryRender: false,
      hideMyAccount: false,
      plan: {
        plan_name: null,
        card_last_4: null,
        card_network: null,
        subscription_start_date: null,
        subscription_end_date: null,
        subscription_status: null,
        subscription_end_date_epoc: null,
        subscription_start_date_epoc: null,
        reason: null,
        plan_type: null,
      },
      currentPlan: null,
      currencySymbol: '',
      colorSettings: null,
      prepaidUsage: [],
      currentPrepaidPrice: 0,
      newPrepaidPrice: 0
    }
  },
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    mode: {
      type: String
    }
  },
  components: {
    BillingHistory,
    Model,
    MinusPlus
  },
  methods: {
    async initPayment() {
      if (!this.customerIdentifier) {
        if (this.workflowSettings.redirect_url_not_login) {
          window.location = this.workflowSettings.redirect_url_not_login
        } else {
          console.error('Redirect url if not logged in not set')
        }
        return
      }
      this.showOverlay = true
      const headers = {
        'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
      }
      const payload = {
        company_id: this.companyId,
        country_id: this.countryId,
        customer_identifier: this.customerIdentifier,
        email: this.email,
        payment_component: 'SAVE_CARD'
      }
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/payment`, payload, {headers: headers})
        if (data.status) {
          const val = data.value
          const mode = val.subscription_mode
          switch (mode) {
            case 'DIRECT':
              if (this.workflowSettings.redirect_url_after_payment)
                window.location = this.workflowSettings.redirect_url_after_payment
              else
                console.log('Please configure redirect url')
              break
            case 'PAYMENT':
              if (val.gateway === 'STRIPE' || !val.gateway)
                window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/${val.transaction_tsid}`
              if (val.gateway === 'PAYPAL')
                window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/p/${val.transaction_tsid}`
              if (val.gateway === 'RAZORPAY')
                window.location = `${process.env.VUE_APP_CHECKOUT_BASE_URL}/r/${val.transaction_tsid}`
              break
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.showOverlay = false
      }
    },
    async getMyAccount() {
      this.showOverlay = true
      try {
        const headers = {
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        let url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/customer/${this.customerIdentifier}`
        const {data} = await axios.get(url, {headers: headers})
        if (data.status && data.value && data.value.plan) {
          return data.value;
        } else {
          this.hideMyAccount = true
        }
      } catch (e) {
        if (e.response.status !== 200) {
          this.hideMyAccount = true
        }
        console.error(e)
      } finally {
        this.showOverlay = false
      }
    },
    async setMyAccountData(data) {
      if (data.plan) {
        let plan_data = data.plan
        this.plan.plan_name = plan_data.name;
        this.plan.card_network = data.card_network;
        this.plan.card_last_4 = data.card_last_4;
        this.plan.subscription_start_date = this.convertEpocToStr(data.subscription_start_date);
        this.plan.subscription_end_date = this.convertEpocToStr(data.subscription_end_date);
        this.plan.subscription_end_date_epoc = data.subscription_end_date;
        this.plan.subscription_start_date_epoc = data.subscription_start_date;
        this.plan.subscription_status = data.subscription_status;
        this.plan.reason = data.reason
        this.plan.plan_type = data.plan_type
      }
    },
    async setPrepaidUsageData(data) {
      if (data.plan) {
        const planType = data.plan_type
        const prepaidUsage = data.prepaid_usage
        const prepaidUsageMap = {}
        for (let i = 0; i < prepaidUsage.length; i++) {
          const meterId = prepaidUsage[i].meter_id
          const qty = prepaidUsage[i].qty
          prepaidUsageMap[meterId] = qty
        }
        const prices = data.plan.price
        for (let i = 0; i < prices.length; i++) {
          const price = prices[i]
          const mode = price.mode
          const label = price.label
          const tsid = price.tsid
          const pricingModel = price.pricing_model
          if (pricingModel === 'FIXED') {
            continue;
          }
          const frequency = price.frequency
          let fre = null
          if (mode === 'PREPAID') {
            if (planType === 'MONTHLY') {
              fre = frequency.month
            }
            if (planType === 'YEARLY') {
              fre = frequency.year
            }
            if (fre) {
              const min = fre[0].min
              const max = 10000
              this.prepaidUsage.push(
                  {
                    meterId: tsid,
                    label: label,
                    qty: prepaidUsageMap[tsid] || 0,
                    min: min,
                    max: max,
                    range: fre,
                    pricingModel: pricingModel
                  }
              )
            }
          }
        }
      }
      this.currentPrepaidPrice = this.calculatePrepaidUsagePrice()
      this.newPrepaidPrice = this.currentPrepaidPrice
    },
    convertEpocToStr(epoc) {
      return moment(new Date(epoc)).format(process.env.VUE_APP_DATE_DEBUG ? 'DD-MMM-YYYY HH:mm' : 'DD-MMM-YYYY')
    },
    async getCountryDetails() {
      try {
        if (this.countryId) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
          }
          const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/country/${this.countryId}`
          const {data} = await axios.get(url, {
            headers: headers
          })
          return data.value
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    async cancelSubscriptionButtonClick() {
      this.$confirm(
          {
            message: 'Are you sure you want to cancel your subscription?',
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              console.log(confirm)
              if (confirm) {
                this.cancelSubscription();
              }
            }
          }
      )
    },
    async cancelSubscription() {
      try {
        this.showOverlay = true
        const headers = {
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        let url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/customer/${this.customerIdentifier}/cancel_subscription`
        await axios.post(url, null, {headers: headers})
      } catch (e) {
        console.log(e)
      } finally {
        this.showOverlay = false
      }
    },
    async openModel() {
      this.$refs.model.openModal();
    },
    async getMyAccountColorSettings() {
      this.showOverlay = true
      try {
        const headers = {
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        let url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/settings?type=MY_ACCOUNT_COLOR_SETTINGS`
        const {data} = await axios.get(url, {headers: headers})
        if (data.status && data.value && data.value.setting) {
          this.colorSettings = data.value.setting
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.showOverlay = false
      }
    },
    getBackgroundColor() {
      if ((!this.mode || this.mode === 'LIGHT') && this.colorSettings?.background_color) {
        return this.colorSettings.background_color
      }
      if (this.mode === 'DARK' && this.colorSettings?.dark_mode?.background_color) {
        return this.colorSettings?.dark_mode?.background_color
      }
      return 'inherit'
    },
    getTextColor() {
      if ((!this.mode || this.mode === 'LIGHT') && this.colorSettings?.text_color) {
        return this.colorSettings?.text_color
      }
      if (this.mode === 'DARK' && this.colorSettings?.dark_mode?.text_color) {
        return this.colorSettings?.dark_mode?.text_color
      }
      return 'inherit'
    },
    getLineBorderColor() {
      if ((!this.mode || this.mode === 'LIGHT') && this.colorSettings?.line_color) {
        return this.colorSettings?.line_color
      }
      if (this.mode === 'DARK' && this.colorSettings?.dark_mode?.line_color) {
        return this.colorSettings?.dark_mode?.line_color
      }
      return 'rgb(178, 175, 175)'
    },
    getSubscriptionPeriodText() {
      if (this.plan.subscription_status === 'ACTIVE' || this.plan.subscription_status === 'TRIAL') {
        return `Subscription Period: ${this.plan.subscription_start_date} - ${this.plan.subscription_end_date}`
      }
      if (this.plan.subscription_status === 'CANCELLED') {
        if (this.plan.subscription_end_date_epoc > new Date().getTime()) {
          return `Plan will cancel on: ${this.plan.subscription_end_date}`
        } else {
          return `Cancelled at: ${this.plan.subscription_end_date}`
        }
      }
      if (this.plan.subscription_status === 'ON_HOLD' || this.plan.subscription_status === 'PAUSE') {
        return `Subscription is paused.` + (this.plan.reason ? `<br/><span style="color: red">${this.plan.reason}</span>` : '')
      }
    },
    calculatePrepaidUsagePrice() {
      let amount = 0
      for (let i = 0; i < this.currentPlan.price.length; i++) {
        const item = this.currentPlan.price[i]
        const pricing_model = item.pricing_model;
        if (pricing_model === 'FIXED') {
          const frequency = item.frequency
          if (this.plan.plan_type === 'MONTHLY') {
            amount += frequency.month[0].price
          }
          if (this.plan.plan_type === 'YEARLY') {
            amount += frequency.year[0].price
          }
        }
      }
      for (let i = 0; i < this.prepaidUsage.length; i++) {
        const meter = this.prepaidUsage[i]
        const pricingModel = meter.pricingModel
        switch (pricingModel) {
          case 'STAIR':
            amount += util.calculateStairCasePrice(meter.qty, meter.range)
            break
          case 'VOLUME':
            amount += util.calculateVolumnPrice(meter.qty, meter.range)
            break
          case 'TIERED':
            amount += util.calculateTieredPrice(meter.qty, meter.range)
            break
        }
      }
      return amount;
    },
    onPrepaidUsageUpdate(data) {
      const index = data.index
      const qty = parseInt(data.value)
      this.prepaidUsage[index].qty = qty
      this.newPrepaidPrice = this.calculatePrepaidUsagePrice()
    },
    async updatePrepaidMeter() {
      this.newPrepaidPrice = this.calculatePrepaidUsagePrice()
      const totalSpan = this.plan.subscription_end_date_epoc - this.plan.subscription_start_date_epoc
      const remainingSpan = this.plan.subscription_end_date_epoc - new Date().getTime()
      const diff = this.newPrepaidPrice - this.currentPrepaidPrice
      const proRate = ((diff * remainingSpan) / totalSpan).toFixed(2)
      if (proRate && proRate > 0) {
        this.$confirm(
            {
              message: `Change in usage. You have to pay ${this.currencySymbol}${proRate} now. Please confirm.`,
              button: {
                no: 'No',
                yes: 'Yes'
              },
              callback: confirm => {
                console.log(confirm)
                if (confirm) {
                  this.makePayment()
                }
              }
            }
        )
      }
    },
    async makePayment() {
      this.showOverlay = true
      const headers = {
        'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
      }
      const usage = []
      for (let i = 0; i < this.prepaidUsage.length; i++) {
        usage.push({meter_id: this.prepaidUsage[i].meterId, qty: this.prepaidUsage[i].qty})
      }
      const payload = {
        company_id: this.companyId,
        country_id: this.countryId,
        customer_identifier: this.customerIdentifier,
        email: this.email,
        payment_component: 'PREPAID_METER_UPDATE',
        usage: usage
      }
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/payment`, payload, {headers: headers})
        if (data.status) {
          const message = 'Payment Processed Successfully'
          this.$notify({group: 'plan_preview', type: 'success', title: 'Success', text: message});
        }
      } catch (e) {
        const data = e.response.data
        const message = data?.error?.message || 'Something went wrong. Please try again'
        this.$notify({group: 'plan_preview', type: 'error', title: 'Error', text: message});
      } finally {
        this.showOverlay = false
      }
    }
  },
  async mounted() {
    this.showOverlay = true
    await this.getMyAccountColorSettings();
    const data = await this.getMyAccount();
    this.currentPlan = data.plan;
    await this.setMyAccountData(data)
    await this.setPrepaidUsageData(data)
    const countryDetails = await this.getCountryDetails();
    this.currencySymbol = countryDetails.currency_symbol;
    this.billingHistoryRender = true;
    this.showOverlay = false
  }
}
</script>

<style scoped>
.row_border {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(178, 175, 175);
  width: 100%;
}

.card {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 20px 30px;
  gap: 13px;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
}

.cookieHeading {
  font-size: 1.6em;
  font-weight: 600;
  /*color: rgb(26, 26, 26);*/
}

.cookieDescription {
  text-align: left;
  font-size: 1.0em;
  font-weight: 400;
  /*color: rgb(59, 59, 59);*/
}

.cookieDescription a {
  /*color: rgb(59 130 246);*/
  text-decoration: underline;
}

.cookieDescription a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

</style>
